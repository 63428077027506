import React from "react";
import { useWindowSize } from "@react-hook/window-size";
import { ThemeContext } from "./ThemeContext";

const Title = () => {
  const [width] = useWindowSize();
  const { theme } = React.useContext(ThemeContext);
  const fill = theme === "dark" ? "#0C1B30" : "#1f3558";
  const stroke = "#aedffa";

  const titleSize = {
    width: 350,
    height: 185,
  };

  if (width < 672) {
    titleSize.width = 350;
    titleSize.height = 185;
  } else if (width < 1296) {
    titleSize.width = 500;
    titleSize.height = 265;
  } else {
    titleSize.width = 750;
    titleSize.height = 397;
  }

  return (
    <div className="relative px-1 py-2">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={titleSize.width}
        height={titleSize.height}
        viewBox="0 0 271.3 150.41"
        preserveAspectRatio="xMidYMid meet"
        className=""
      >
        <g>
          <path
            d="M78.81,150.4h-19.83l-20.54-32.67,21.25-33.73h17.71l-21.96,33.11,23.38,33.29h0Z"
            fill={fill}
          />
          <path d="M97.55,84v66.4h-14.37V84h14.37Z" fill={fill} />
          <path
            d="M116.96,84l44.37,66.4h-17.15l-26.25-39.29v39.29h-14.37V84h13.4Zm42.64,0v58.64l-14.37-21.56v-37.08h14.37Z"
            fill={fill}
          />
          <path
            d="M232.86,112.69l-22.31,35.86c-1.71,.59-3.54,1.05-5.49,1.37-1.95,.33-3.95,.49-6.02,.49-2.6,0-5.08-.27-7.44-.8-3.84-.83-7.36-2.24-10.58-4.25s-6.01-4.44-8.37-7.3-4.21-6.09-5.53-9.7c-1.33-3.6-1.99-7.44-1.99-11.51,0-4.78,.9-9.18,2.7-13.19s4.26-7.48,7.39-10.4,6.76-5.19,10.89-6.82c4.13-1.62,8.56-2.43,13.28-2.43,2.66,0,5.28,.31,7.88,.93s5.09,1.52,7.48,2.7,4.63,2.63,6.73,4.34c2.09,1.71,3.91,3.63,5.45,5.76,2.01,2.78,3.45,5.73,4.34,8.85h-18.24c-1.54-2.66-3.51-4.71-5.93-6.15-2.42-1.45-5.14-2.17-8.15-2.17-2.6,0-4.97,.5-7.13,1.51-2.16,1-4,2.35-5.53,4.03-1.54,1.68-2.73,3.66-3.59,5.93s-1.28,4.65-1.28,7.13,.41,5.06,1.24,7.39,1.99,4.37,3.5,6.11c1.5,1.74,3.33,3.13,5.49,4.16,2.15,1.03,4.59,1.55,7.3,1.55,.35,0,.68,0,.97-.04,.29-.03,.59-.05,.89-.05l6.99-10.18h-12.57v-13.1h37.63v-.02Z"
            fill={fill}
          />
        </g>
        <g>
          <path
            d="M40.26,66.4H20.48L0,33.83,21.19,.19h17.66L16.95,33.21l23.31,33.19Z"
            fill={fill}
          />
          <path
            d="M71.09,0l27.57,66.4H61.93l5.68-13.74h10.72c-.67-1.53-1.3-2.96-1.88-4.3-.58-1.34-1.14-2.67-1.69-3.98-.55-1.31-1.11-2.67-1.69-4.08-.58-1.4-1.24-2.99-1.97-4.76l-12.91,30.87h-14.84L71.09,0Z"
            fill={fill}
          />
          <path
            d="M122.97,0C127.56,0,131.87,.86,135.89,2.58c4.02,1.72,7.53,4.1,10.52,7.13,3,3.03,5.37,6.55,7.13,10.57,1.75,4.02,2.63,8.32,2.63,12.92s-.88,8.9-2.63,12.92c-1.75,4.02-4.13,7.53-7.13,10.52-3,3-6.51,5.38-10.52,7.13-4.02,1.76-8.32,2.63-12.92,2.63h-20.19V17.41h14.26V52.05h5.93c2.61,0,5.07-.49,7.37-1.48,2.3-.99,4.29-2.33,5.98-4.02s3.03-3.68,4.02-5.98,1.48-4.75,1.48-7.37-.49-5.07-1.48-7.37c-.99-2.3-2.33-4.29-4.02-5.98-1.69-1.69-3.68-3.03-5.98-4.02s-4.75-1.48-7.37-1.48h-20.19V0h20.19Z"
            fill={fill}
          />
          <path
            d="M207.73,52.05v14.35h-15.02c-4.59,0-8.9-.88-12.92-2.63-4.02-1.75-7.53-4.13-10.53-7.13s-5.36-6.51-7.08-10.52c-1.72-4.02-2.58-8.32-2.58-12.92s.86-8.9,2.58-12.92,4.08-7.53,7.08-10.52c3-3,6.51-5.37,10.53-7.13,4.02-1.75,8.32-2.63,12.92-2.63h15.02V14.35h-15.02c-2.62,0-5.06,.5-7.32,1.48-2.27,.99-4.24,2.33-5.93,4.02-1.69,1.69-3.03,3.68-4.02,5.98s-1.48,4.75-1.48,7.37,.49,5.07,1.48,7.37c.99,2.3,2.33,4.29,4.02,5.98s3.67,3.03,5.93,4.02,4.7,1.48,7.32,1.48h15.02Zm0-26.03v14.35h-29.09c-.64-1.53-1.09-2.89-1.34-4.07s-.38-2.22-.38-3.11c0-1.08,.13-2.18,.38-3.3,.25-1.12,.64-2.41,1.15-3.88h29.28Z"
            fill={fill}
          />
          <path
            d="M226.93,0l44.37,66.4h-17.15l-26.25-39.29v39.29h-14.37V0h13.4ZM269.57,0V58.64l-14.37-21.56V0h14.37Z"
            fill={fill}
          />
        </g>
      </svg>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={titleSize.width}
        height={titleSize.height}
        viewBox="0 0 273.21 152.65"
        preserveAspectRatio="xMidYMid meet"
        className="absolute top-0 left-0 animate-pulse"
      >
        <g>
          <path
            d="M79.55,152.03h-19.83l-20.54-32.67,21.25-33.73h17.71l-21.96,33.11,23.38,33.29h0Z"
            fill="none"
            stroke={stroke}
          />
          <path d="M98.29,85.63v66.4h-14.37V85.63h14.37Z" fill="none" stroke={stroke} />
          <path
            d="M117.7,85.63l44.37,66.4h-17.15l-26.25-39.29v39.29h-14.37V85.63h13.4Zm42.64,0v58.64l-14.37-21.56v-37.08h14.37Z"
            fill="none"
            stroke={stroke}
          />
          <path
            d="M233.6,114.31l-22.31,35.86c-1.71,.59-3.54,1.05-5.49,1.37-1.95,.33-3.95,.49-6.02,.49-2.6,0-5.08-.27-7.44-.8-3.84-.83-7.36-2.24-10.58-4.25s-6.01-4.44-8.37-7.3-4.21-6.09-5.53-9.7c-1.33-3.6-1.99-7.44-1.99-11.51,0-4.78,.9-9.18,2.7-13.19s4.26-7.48,7.39-10.4,6.76-5.19,10.89-6.82c4.13-1.62,8.56-2.43,13.28-2.43,2.66,0,5.28,.31,7.88,.93s5.09,1.52,7.48,2.7,4.63,2.63,6.73,4.34c2.09,1.71,3.91,3.63,5.45,5.76,2.01,2.78,3.45,5.73,4.34,8.85h-18.24c-1.54-2.66-3.51-4.71-5.93-6.15s-5.14-2.17-8.15-2.17c-2.6,0-4.97,.5-7.13,1.51-2.16,1-4,2.35-5.53,4.03-1.54,1.68-2.73,3.66-3.59,5.93s-1.28,4.65-1.28,7.13,.41,5.06,1.24,7.39,1.99,4.37,3.5,6.11c1.5,1.74,3.33,3.13,5.49,4.16,2.15,1.03,4.59,1.55,7.3,1.55,.35,0,.68,0,.97-.04,.29-.03,.59-.05,.89-.05l6.99-10.18h-12.57v-13.1h37.63v-.02Z"
            fill="none"
            stroke={stroke}
          />
        </g>
        <g>
          <path
            d="M41,68.03H21.22L.74,35.45,21.93,1.82h17.66L17.69,34.83l23.31,33.19h0Z"
            fill="none"
            stroke={stroke}
          />
          <path
            d="M71.83,1.63l27.57,66.4H62.67l5.68-13.74h10.72c-.67-1.53-1.3-2.96-1.88-4.3-.58-1.34-1.14-2.67-1.69-3.98-.55-1.31-1.11-2.67-1.69-4.08-.58-1.4-1.24-2.99-1.97-4.76l-12.91,30.87h-14.84L71.83,1.63Z"
            fill="none"
            stroke={stroke}
          />
          <path
            d="M123.7,1.63c4.59,0,8.9,.86,12.92,2.58,4.02,1.72,7.53,4.1,10.52,7.13,3,3.03,5.37,6.55,7.13,10.57,1.75,4.02,2.63,8.32,2.63,12.92s-.88,8.9-2.63,12.92c-1.75,4.02-4.13,7.53-7.13,10.52-3,3-6.51,5.38-10.52,7.13-4.02,1.76-8.32,2.63-12.92,2.63h-20.19V19.04h14.26V53.68h5.93c2.61,0,5.07-.49,7.37-1.48,2.3-.99,4.29-2.33,5.98-4.02s3.03-3.68,4.02-5.98,1.48-4.75,1.48-7.37-.49-5.07-1.48-7.37c-.99-2.3-2.33-4.29-4.02-5.98-1.69-1.69-3.68-3.03-5.98-4.02s-4.75-1.48-7.37-1.48h-20.19V1.63h20.19Z"
            fill="none"
            stroke={stroke}
          />
          <path
            d="M208.46,53.68v14.35h-15.02c-4.59,0-8.9-.88-12.92-2.63s-7.53-4.13-10.53-7.13-5.36-6.51-7.08-10.52-2.58-8.32-2.58-12.92,.86-8.9,2.58-12.92,4.08-7.53,7.08-10.52c3-3,6.51-5.37,10.53-7.13,4.02-1.75,8.32-2.63,12.92-2.63h15.02V15.98h-15.02c-2.62,0-5.06,.5-7.32,1.48-2.27,.99-4.24,2.33-5.93,4.02-1.69,1.69-3.03,3.68-4.02,5.98s-1.48,4.75-1.48,7.37,.49,5.07,1.48,7.37c.99,2.3,2.33,4.29,4.02,5.98s3.67,3.03,5.93,4.02,4.7,1.48,7.32,1.48h15.02Zm0-26.03v14.35h-29.09c-.64-1.53-1.09-2.89-1.34-4.07s-.38-2.22-.38-3.11c0-1.08,.13-2.18,.38-3.3,.25-1.12,.64-2.41,1.15-3.88h29.28Z"
            fill="none"
            stroke={stroke}
          />
          <path
            d="M227.67,1.63l44.37,66.4h-17.15l-26.25-39.29v39.29h-14.37V1.63h13.4Zm42.64,0V60.27l-14.37-21.56V1.63h14.37Z"
            fill="none"
            stroke={stroke}
          />
        </g>
      </svg>
    </div>
  );
};

export default Title;
